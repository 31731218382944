const imgs = [
  ["summer_camp_img_136", "summer_camp_img_139", "summer_camp_img_146", "summer_camp_img_104"],
  ["summer_camp_img_157", "summer_camp_img_4", "summer_camp_img_15", "summer_camp_img_16"],
  ["summer_camp_img_17", "summer_camp_img_18", "summer_camp_img_21", "summer_camp_img_35"],
  ["summer_camp_img_79", "summer_camp_img_83", "summer_camp_img_97", "summer_camp_img_113"],
  ["summer_camp_img_152", "summer_camp_img_154", "summer_camp_img_156", "summer_camp_img_2"],
  ["summer_camp_img_117", "summer_camp_img_125", "summer_camp_img_127", "summer_camp_img_132"]
];

export default imgs;
